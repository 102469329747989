import React, { useState, useEffect } from 'react';
import { CategoryMap } from '../../../utils/enumerator/categoryEnumerator';
import ProductCard from '../../shared/productCard/ProductCard';
import { getProductsByCategory } from '../../../utils/helpers/productFilters';
import ProductDetails from '../../produto/productDetails/ProductDetails';
import ProductDetailsMobile from '../../produto/productDetailsMobile/ProductDetailsMobile';
import SimilarProducts from '../../produto/similarProducts/SimilarProducts';
import { categoryPipe } from '../../../utils/helpers/pipes';
import { FaChevronLeft, FaFilter, FaTimes, FaSearch } from 'react-icons/fa';
import './ProductList.scss';

const ProductList = ({ product = null, selectedCategory = [0, 'Todas'] }) => {
  const [category, setCategory] = useState({ id: selectedCategory && selectedCategory[0] || 0, name: selectedCategory && selectedCategory[1] || 'Todas' });
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [searchProduct, setSearchProduct] = useState('');
  const [productCounter, setProductCounter] = useState();

  useEffect(() => {
    if (product) {
      selectProduct(product);
    } else {
      setProductList(category.id);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const setProductList = (id) => {
    const list = getProductsByCategory(id);
    setProducts(list);
    setProductCounter(list.length);
  }

  const changeCategory = (c) => {
    setCategory({ id: c[0], name: c[1] });
    setProductList(c[0]);
    setSelectedProduct(null);
    scrollToTop();
    closeCategoryMobile();
    setSearchProduct('');
    filterProducts('');
  }

  const selectProduct = (product) => {
    setCategory({ id: product.category, name: categoryPipe(product.category) });
    setSelectedProduct(product);
    scrollToTop();
  }

  const unselectProduct = (category) => {
    setSelectedProduct(null);
    setCategory({ id: category.id, name: category.name });
    setProductList(category.id);
    scrollToTop();
  }

  const openCategoryMobile = () => {
    setOverlay(true);
  }

  const closeCategoryMobile = () => {
    setOverlay(false);
  }

  const onSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    filterProducts(e.target.value);
  }

  const filterProducts = (str) => {
    const filtered = products.filter(e => e.name.toLowerCase().includes(str.toLowerCase()));
    if (!str) {
      setProductCounter(products.length);
    } else {
      setProductCounter(filtered.length);
    }
  }

  return (
    <section className="category-container">
      <section className="category-selector mobile-hidden">
        <div className="category-selector__title">
          Linhas
        </div>
        <div className="category-selector__list">
          {Array.from(CategoryMap).map(c =>
            (
              <button
                key={c[0]}
                type="button"
                className={`category-selector__type 
              ${c[0] === category.id ? 'category-selector__type--selected' : ''}`}
                onClick={() => { changeCategory(c) }}>
                {c[1]}
              </button>
            ))
          }
        </div>
      </section>
      <section className="category-products">
        {!selectedProduct ? (
          <>
            <div className="category-products__title">
              {category.name} <span>({productCounter || 0})</span>
              <section className={`category-selector--mobile mobile ${overlay ? 'category-selector--mobile--open' : ''}`}>
                <div className={`category-selector--mobile__trigger ${overlay ? 'category-selector--mobile__trigger--open' : ''}`}>
                  {!overlay ? (
                    <FaFilter className="open-icon" size={24} onClick={openCategoryMobile} />
                  ) :
                    (
                      <FaTimes className="close-icon" size={24} onClick={closeCategoryMobile} />
                    )}
                </div>
                <div className="category-selector--mobile__list">
                  {Array.from(CategoryMap).map(c =>
                    (
                      <button
                        key={c[0]}
                        type="button"
                        className={`category-selector--mobile__type
                ${c[0] === category.id ? 'category-selector--mobile__type--selected' : ''}`}
                        onClick={() => { changeCategory(c) }}>
                        {c[1]}
                      </button>
                    ))
                  }
                </div>
              </section>
              <div className="category-products__search--wrapper">
                <div className="category-products__search--divider mobile-hidden" />
                <FaSearch />
                <input
                  type="text"
                  className="category-products__search"
                  value={searchProduct}
                  placeholder="Buscar por nome"
                  onChange={onSearchProduct} />
              </div>
            </div>
            <div className="category-products__title--divider" />
          </>
        ) : (
            <div className="category-products__title--back">
              <button
                type="button"
                onClick={() => unselectProduct(category)}
              >
                <FaChevronLeft size={14} />
                voltar para {category.name}
              </button>
            </div>
          )}
        {products && products.length > 0 && !selectedProduct && (
          <div className="category-products__view">
            {products.map(p => (
              <div
                key={p.ref}
                role="button"
                className={`category-products__single 
                ${searchProduct && !((p.name).toLowerCase().includes(searchProduct.toLowerCase())) ? 'category-products__single--hidden' : ''}`}
                onClick={() => selectProduct(p)}
              >
                <ProductCard product={p} />
              </div>
            ))}
          </div>
        )}
        {selectedProduct && (
          <>
            <div className="category-products__view--center">
              <div className="category-products__selected mobile-hidden">
                <ProductDetails product={selectedProduct} />
              </div>
              <div className="category-products__selected mobile">
                <ProductDetailsMobile product={selectedProduct} />
              </div>
            </div>
            <SimilarProducts
              product={selectedProduct}
              selectProduct={selectProduct}
            />
          </>
        )}
      </section>
    </section>
  )
}

export default ProductList;

import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ProductList from '../components/produtos/productList/ProductList';

const ProductListPage = ({ location }) => {
  return (
    <Layout hideFooter>
      <SEO title="Produtos" />
      <ProductList
        product={location && location.state && location.state.product}
        selectedCategory={location && location.state && location.state.category}
      />
    </Layout>
  )
}

export default ProductListPage;

import React, { useState, useEffect } from 'react';
import { multipleColorsPipe, packagePipe } from '../../../../utils/helpers/pipes';
import './SimilarProductCard.scss';

const SimilarProductCard = ({ product }) => {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const res = multipleColorsPipe(product.colors);
    setColors(res);
  }, []);

  return (
    <div className="similar__product-card">
      <header>
        <img
          className="similar__product-image"
          src={product.images[0]}
        />
      </header>
      <div className="similar__product-card__info">
        <span className="similar__product-card__name">
          {product.name}
        </span>
        <div className="similar__product-card-extra">
          <div className="similar__product-card-extra__ref">
            Ref. {product.ref}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimilarProductCard;

import { CategoryMap } from "../enumerator/categoryEnumerator";
import { PackageMap } from "../enumerator/packageEnumerator";
import { ColorMap } from "../enumerator/colorEnumerator";

export const categoryPipe = (id) => (CategoryMap.get(id));

export const packagePipe = (id) => (PackageMap.get(id));

const colorPipe = (id) => (ColorMap.get(id));

export const multipleColorsPipe = (colorArray) => {
  const colors = colorArray.map(c => (colorPipe(c)));
  return colors;
}

const ColorEnumerator = {
  GREEN: 1,
  RED: 2,
  BLUE: 3,
  PINK: 4,
  YELLOW: 5,
  BLACK: 6,
  WHITE: 7,
  NATURAL: 8,
  BABY_GREEN: 9,
  BABY_BLUE: 10,
  BABY_PINK: 11,
  PURPLE: 12,
  TURQUOISE: 13,
  GREY: 14,
};

export const ColorMap = new Map([
  [ColorEnumerator.GREEN, { name: 'Verde', hex: '#34bf49' }],
  [ColorEnumerator.RED, { name: 'Vermelho', hex: '#e30003' }],
  [ColorEnumerator.BLUE, { name: 'Azul', hex: '#3369e7' }],
  [ColorEnumerator.PINK, { name: 'Rosa', hex: '#ed008c' }],
  [ColorEnumerator.YELLOW, { name: 'Amarelo', hex: '#ffdd00' }],
  [ColorEnumerator.BLACK, { name: 'Preto', hex: '#000000' }],
  [ColorEnumerator.WHITE, { name: 'Branco', hex: '#ffffff' }],
  [ColorEnumerator.NATURAL, { name: 'Natural', hex: 'transparent' }],
  [ColorEnumerator.BABY_GREEN, { name: 'Verde claro', hex: '#cffae1' }],
  [ColorEnumerator.BABY_BLUE, { name: 'Azul claro', hex: '#89cff0' }],
  [ColorEnumerator.BABY_PINK, { name: 'Rosa claro', hex: '#ffc2e5' }],
  [ColorEnumerator.PURPLE, { name: 'Roxo', hex: '#9628c6' }],
  [ColorEnumerator.TURQUOISE, { name: 'Verde turquesa', hex: '#0abab5' }],
  [ColorEnumerator.GREY, { name: 'Cinza', hex: '#c0c6c8' }],
]);

export default ColorEnumerator;

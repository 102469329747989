import React, { useState, useEffect } from "react"
import { multipleColorsPipe, packagePipe } from "../../../utils/helpers/pipes"
import "./ProductCard.scss"

const ProductCard = ({ product }) => {
  const [colors, setColors] = useState([])

  useEffect(() => {
    const res = multipleColorsPipe(product.colors)
    setColors(res)
  }, [])

  return (
    <div className="product-card">
      {product.new && (
        <div className="product-card__new-ribbon mobile">lançamento</div>
      )}
      <header>
        <img loading="lazy" className="product-image" src={product.images[0]} />
      </header>
      <div className="product-card__info">
        <span className="product-card__name">{product.name}</span>
        <div className="product-card-extra">
          <div className="product-card-extra__ref">Ref. {product.ref}</div>
          {product.new && (
            <div className="product-card__new-ribbon mobile-hidden">
              lançamento
            </div>
          )}
        </div>
        <div className="product-card--hide-on-hover mobile-hidden">
          {colors.length > 1 ? `${colors.length} cores` : `1 cor`}
        </div>
        <div className="product-card-hidden__colors mobile">
          {colors &&
            colors.length &&
            colors.map(color => (
              <div
                key={color.hex}
                className={`product-card-hidden__colors__circle
                  ${
                    color.hex === "#ffffff" || color.hex === "transparent"
                      ? "product-details__color__circle--transparent"
                      : ""
                  }`}
                style={{ backgroundColor: color.hex }}
              />
            ))}
        </div>
        <div className="product-card-hidden">
          <section>
            <div className="product-card-hidden__colors">
              {colors &&
                colors.length &&
                colors.map(color => (
                  <div
                    key={color.hex}
                    className={`product-card-hidden__colors__circle
                  ${
                    color.hex === "#ffffff" || color.hex === "transparent"
                      ? "product-details__color__circle--transparent"
                      : ""
                  }`}
                    style={{ backgroundColor: color.hex }}
                  />
                ))}
            </div>
            <div className="product-card-hidden__data">
              <span>
                <strong>P</strong> {product.measures.depth} x <strong>L</strong>{" "}
                {product.measures.width} x <strong>A</strong>{" "}
                {product.measures.height} mm
              </span>
              <span>
                <strong>x{product.quantity}</strong> por{" "}
                {packagePipe(product.package)}
              </span>
              <div className="product-card-hidden__code">
                EAN: {product.code}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ProductCard

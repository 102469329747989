const PackageEnumerator = {
  SC: 1,
  CX: 2,
}

export const PackageMap = new Map([
  [PackageEnumerator.SC, 'saco'],
  [PackageEnumerator.CX, 'caixa'],
]);

export default PackageEnumerator;

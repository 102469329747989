import React, { useEffect, useState } from "react"
import { packagePipe, multipleColorsPipe } from "../../../utils/helpers/pipes"
import "./ProductDetailsMobile.scss"

const ProductDetailsMobile = ({ product }) => {
  const [colors, setColors] = useState([])
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const res = multipleColorsPipe(product.colors)
    setColors(res)
    setCurrentImage(0)
  }, [product])

  return (
    <>
      {product && (
        <>
          <div className="product-details--mobile__wrapper">
            <div className="product-details--mobile__information">
              {product.logo ? (
                <div className="product-details--mobile__name no-padding">
                  <img src={product.logo} width="50%" />
                </div>
              ) : (
                <div className="product-details--mobile__name">
                  {product.name}
                </div>
              )}
              <div className="product-details--mobile__code">
                Ref. {product.ref}
                <span>EAN: {product.code}</span>
              </div>
            </div>
            <div className="product-details--mobile__photo">
              <img
                loading="lazy"
                src={product.images[currentImage]}
                alt={product.name}
                height="400px"
              />
              <div className="product-details--mobile__photo__selector">
                {product.images.map((item, i) => (
                  <button
                    key={item}
                    type="button"
                    className="invisible-button"
                    onClick={() => setCurrentImage(i)}
                  >
                    <div
                      key={item}
                      className={`product-details--mobile__photo__selector__item 
                      ${
                        currentImage === i
                          ? "product-details--mobile__photo__selector__item--selected"
                          : ""
                      }`}
                    >
                      <img src={item} height="75px" />
                    </div>
                  </button>
                ))}
              </div>
            </div>
            <div className="product-details--mobile__extra">
              <div className="product-details--mobile__section">
                <div className="product-details--mobile__section__title">
                  Cores
                </div>
                <div className="product-details--mobile__section__data">
                  <div className="product-details--mobile__color">
                    {colors &&
                      colors.length &&
                      colors.map(color => (
                        <div
                          key={color.hex}
                          className={`product-details--mobile__color__circle 
                        ${
                          color.hex === "#ffffff" || color.hex === "transparent"
                            ? "product-details--mobile__color__circle--transparent"
                            : ""
                        }`}
                          style={{ backgroundColor: color.hex }}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="product-details--mobile__section product-details--mobile__section--flex">
                <section>
                  <div className="product-details--mobile__section__title">
                    Dimensões
                  </div>
                  <div className="product-details--mobile__section__data">
                    <strong>P</strong> {product.measures.depth} x{" "}
                    <strong>L</strong> {product.measures.width} x{" "}
                    <strong>A</strong> {product.measures.height} mm
                  </div>
                </section>
                <section>
                  <div className="product-details--mobile__section__title">
                    Embalagem
                  </div>
                  <div className="product-details--mobile__section__data">
                    <strong>x{product.quantity}</strong> por{" "}
                    {packagePipe(product.package)}
                  </div>
                </section>
              </div>
              <section>
                {product.inmetro && (
                  <div className="product-details__section">
                    <div className="product-details__section__title">
                      Informações adicionais
                    </div>
                    <div className="product-details__section__data">
                      {product.inmetro.description}
                      <br />
                      <strong>Indicação:</strong> {product.inmetro.indication}
                      <br />
                      <strong>Restrição:</strong> {product.inmetro.restriction}
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ProductDetailsMobile

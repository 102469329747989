import products from '../data/products';
import CategoryEnumerator, { CategoryMap } from '../enumerator/categoryEnumerator';

export const getProductsByCategory = (category) =>
  (!category ? products : products.filter(p => (p.category === category)));

export const getProductReleases = () => (products.filter(p => p.new));

export const getProductByReference = (ref) => (products.find(p => p.ref === ref));

export const getSimilarProducts = ({ category, ref }) =>
  (products.filter(p => (p.category === category) && p.ref !== ref));

export const getProductSelect = () => (products.map(p => ({ value: p.ref, label: p.name })));

export const getGroupProductSelect = () => {
  const brinquedos = [];
  const baby = [];
  const organizadores = [];
  const color = [];
  const black = [];
  const kits = [];
  const limpeza = [];
  const cozinha = [];
  const acessorios = [];

  products.forEach(p => {
    switch (p.category) {
      case CategoryEnumerator.BRINQUEDOS:
        brinquedos.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.BABY:
        baby.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.ORGANIZADORES:
        organizadores.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.COLOR:
        color.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.BLACK:
        black.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.KITS:
        kits.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.LIMPEZA:
        limpeza.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.COZINHA:
        cozinha.push({ value: p.ref, label: p.name });
        break;
      case CategoryEnumerator.ACESSORIOS:
        acessorios.push({ value: p.ref, label: p.name });
        break;
    }
  });

  return [
    { label: CategoryMap.get(CategoryEnumerator.BRINQUEDOS), options: brinquedos },
    { label: CategoryMap.get(CategoryEnumerator.BABY), options: baby },
    { label: CategoryMap.get(CategoryEnumerator.ORGANIZADORES), options: organizadores },
    { label: CategoryMap.get(CategoryEnumerator.COLOR), options: color },
    { label: CategoryMap.get(CategoryEnumerator.BLACK), options: black },
    { label: CategoryMap.get(CategoryEnumerator.KITS), options: kits },
    { label: CategoryMap.get(CategoryEnumerator.LIMPEZA), options: limpeza },
    { label: CategoryMap.get(CategoryEnumerator.COZINHA), options: cozinha },
    { label: CategoryMap.get(CategoryEnumerator.ACESSORIOS), options: acessorios },
  ]
}

export default getProductsByCategory;

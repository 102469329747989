import React, { useEffect, useState } from 'react';
import SimilarProductCard from './similarProductCard/SimilarProductCard';
import Slider from "react-slick";
import { getSimilarProducts } from '../../../utils/helpers/productFilters';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SimilarProducts.scss';

const SimilarProducts = ({ product, selectProduct }) => {
  const [similarList, setSimilarList] = useState();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    const res = getSimilarProducts(product);
    setSimilarList(res);
  }, []);

  return (
    <section className="similar-products">
      <div className="similar-products__title">
        Produtos similares
      </div>
      <div className="similar-products__view">
        {similarList && similarList.length > 0 && (
          <Slider {...settings}>
            {similarList.map(p => (
              <div
                key={p.ref}
                className="similar-products__single"
                onClick={() => { selectProduct(p) }}
              >
                <SimilarProductCard product={p} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  )
}

export default SimilarProducts;

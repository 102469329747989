export const CategoryEnumerator = {
  ALL: 0,
  BRINQUEDOS: 1,
  BABY: 2,
  ORGANIZADORES: 3,
  COLOR: 4,
  BLACK: 5,
  KITS: 6,
  LIMPEZA: 7,
  COZINHA: 8,
  ACESSORIOS: 9
};

export const CategoryMap = new Map([
  [CategoryEnumerator.ALL, 'Todas'],
  [CategoryEnumerator.BRINQUEDOS, 'Brinquedos'],
  [CategoryEnumerator.BABY, 'Baby'],
  [CategoryEnumerator.ORGANIZADORES, 'Organizadores'],
  [CategoryEnumerator.COLOR, 'Color'],
  [CategoryEnumerator.BLACK, 'Black'],
  [CategoryEnumerator.KITS, 'Kits'],
  [CategoryEnumerator.LIMPEZA, 'Limpeza'],
  [CategoryEnumerator.COZINHA, 'Mesa & Cozinha'],
  [CategoryEnumerator.ACESSORIOS, 'Acessórios'],
]);

export default CategoryEnumerator;

import ColorEnumerator from "../enumerator/colorEnumerator"
import PackageEnumerator from "../enumerator/packageEnumerator"
import CategoryEnumerator from "../enumerator/categoryEnumerator"

const products = [
  {
    ref: 373,
    name: "Regador Infantil",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 95 1",
    measures: {
      depth: 194,
      width: 90,
      height: 100,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.PINK,
      ColorEnumerator.RED,
      ColorEnumerator.BLUE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/373.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/373_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/373_extra.jpg",
    ],
    new: true,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/373_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 372,
    name: "Kit Regador Infantil",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 94 4",
    measures: {
      depth: 195,
      width: 90,
      height: 200,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.BLUE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/372.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/372_pack.jpg",
    ],
    new: true,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/372_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 388,
    name: "Castelinho de Regar",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898679150 12 5",
    measures: {
      depth: 190,
      width: 190,
      height: 290,
    },
    package: PackageEnumerator.CX,
    quantity: 12,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.BLUE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/388.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/388_pack.jpg",
    ],
    new: true,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/388_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 363,
    name: "Mini Market",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 85 2",
    measures: {
      depth: 460,
      width: 420,
      height: 460,
    },
    package: PackageEnumerator.CX,
    quantity: 2,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/363_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/363_pack.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/363_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "-03 anos",
    },
  },
  {
    ref: 312,
    name: "Baby Passeio",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 12 8",
    measures: {
      depth: 480,
      width: 300,
      height: 480,
    },
    package: PackageEnumerator.SC,
    quantity: 5,
    colors: [ColorEnumerator.PINK, ColorEnumerator.PURPLE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/312.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/312_pack.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/312_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+03 anos",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 339,
    name: "Super Comprinha",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 39 5",
    measures: {
      depth: 600,
      width: 300,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.PINK, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/339.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/339_pack.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/339_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "-03 anos",
    },
  },
  {
    ref: 341,
    name: "Lev & Brink",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 41 8",
    measures: {
      depth: 600,
      width: 300,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.PINK, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/341.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/341_pack.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/341_extra.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/341_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 348,
    name: "Super Caçamba com Pá e Rastelo",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 68 5",
    measures: {
      depth: 400,
      width: 210,
      height: 230,
    },
    package: PackageEnumerator.SC,
    quantity: 4,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.BLUE, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/348.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/348_extra.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/348_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 347,
    name: "Super Trator",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 67 8",
    measures: {
      depth: 440,
      width: 190,
      height: 200,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/347.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/347_extra.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/347_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 314,
    name: "Super Carriola",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 14 2",
    measures: {
      depth: 500,
      width: 280,
      height: 300,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/314.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/314_pack.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/314_extra.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/314_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 354,
    name: "Castelinho de Montar",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 74 6",
    measures: {
      depth: 170,
      width: 170,
      height: 195,
    },
    package: PackageEnumerator.CX,
    quantity: 12,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.RED,
      ColorEnumerator.BLUE,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/354.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/354_pecas.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/354_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+03 anos",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 365,
    name: "Kit de Montar",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 87 6",
    measures: {
      depth: 17,
      width: 17,
      height: 85,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.RED,
      ColorEnumerator.BLUE,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/365.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/365_pecas.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/365_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+03 anos",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 340,
    name: "Castelinho Criativo",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 40 1",
    measures: {
      depth: 190,
      width: 190,
      height: 209,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/340.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/340_extra.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/340_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 355,
    name: "Kit Criativo",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898947043 75 3",
    measures: {
      depth: 140,
      width: 140,
      height: 225,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/355.jpg",
    ],
    new: false,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/355_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+18 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 699,
    name: "Triciclo Tico Kids",
    category: CategoryEnumerator.BRINQUEDOS,
    code: "7898908706 99 4",
    measures: {
      depth: 57,
      width: 36,
      height: 40,
    },
    package: PackageEnumerator.CX,
    quantity: 1,
    colors: [ColorEnumerator.GREEN, ColorEnumerator.RED, ColorEnumerator.BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/699.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/699_extra.jpg",
    ],
    new: true,
    isActive: true,
    logo:
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/brinquedos/logos/699_logo.jpg",
    inmetro: {
      description:
        "Produto certificado pelo IQB, organismo de certificação acreditado pelo Inmetro, sob certificado número CE-BRI/IQB 006022",
      indication: "+24 meses",
      restriction: "Sem restrição",
    },
  },
  {
    ref: 379,
    name: "Lixeira 9,5 litros Baby",
    category: CategoryEnumerator.BABY,
    code: "7898679150 02 6",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/379_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/379_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/379_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 378,
    name: "Lixeira 3,6 litros Baby",
    category: CategoryEnumerator.BABY,
    code: "7898679150 01 9",
    measures: {
      depth: 175,
      width: 175,
      height: 250,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/378_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/378_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/378_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 380,
    name: "Cesta Organizadora Quadrada Baby",
    category: CategoryEnumerator.BABY,
    code: "7898679150 03 3",
    measures: {
      depth: 210,
      width: 210,
      height: 75,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/380.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 381,
    name: "Cesta Organizadora Retangular Baby",
    category: CategoryEnumerator.BABY,
    code: "7898679150 04 0",
    measures: {
      depth: 250,
      width: 160,
      height: 65,
    },
    package: PackageEnumerator.SC,
    quantity: 72,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/381_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/381_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/381_rosa.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 605,
    name: "Saboneteira Retangular Baby Cristal",
    category: CategoryEnumerator.BABY,
    code: "7898908706 05 5",
    measures: {
      depth: 100,
      width: 70,
      height: 45,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/605.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 377,
    name: "Caixa Prática Baby 30 litros",
    category: CategoryEnumerator.BABY,
    code: "7898947043 99 9",
    measures: {
      depth: 460,
      width: 310,
      height: 300,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_extra.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 376,
    name: "Caixa Prática Baby 16 litros",
    category: CategoryEnumerator.BABY,
    code: "7898947043 98 2",
    measures: {
      depth: 420,
      width: 270,
      height: 150,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/376_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/376_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/376_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_extra.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 375,
    name: "Caixa Prática Baby 10 litros",
    category: CategoryEnumerator.BABY,
    code: "7898947043 97 5",
    measures: {
      depth: 330,
      width: 240,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/375_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/375_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/375_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_extra.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 374,
    name: "Caixa Prática Baby 5 litros",
    category: CategoryEnumerator.BABY,
    code: "7898947043 96 8",
    measures: {
      depth: 230,
      width: 180,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/374_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/374_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/374_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/baby/377_extra.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 349,
    name: "Caixa Prática Multiuso 5 litros",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 69 2",
    measures: {
      depth: 230,
      width: 180,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/349.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 682,
    name: "Caixa Prática Multiuso 10 litros",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898908706 82 6",
    measures: {
      depth: 330,
      width: 240,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/682.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 687,
    name: "Caixa Prática Multiuso 16 litros",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898908706 87 1",
    measures: {
      depth: 420,
      width: 270,
      height: 150,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/687.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 306,
    name: "Caixa Prática Multiuso 30 litros",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 06 7",
    measures: {
      depth: 460,
      width: 310,
      height: 300,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/306.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 309,
    name: "Caixa Click com Divisórias Média",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 09 8",
    measures: {
      depth: 230,
      width: 160,
      height: 40,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/309.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 371,
    name: "Caixa Click",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 39 7",
    measures: {
      depth: 19.5,
      width: 13.5,
      height: 40,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/371.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 338,
    name: "Cesto de Roupa Telado 30 litros",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 38 8",
    measures: {
      depth: 370,
      width: 370,
      height: 510,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.BLACK,
      ColorEnumerator.WHITE,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.RED,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/338_branco.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/338_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 655,
    name: "Cesta Organizadora Quadrada",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898908706 65 9",
    measures: {
      depth: 210,
      width: 210,
      height: 75,
    },
    package: PackageEnumerator.SC,
    quantity: 48,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/665.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 664,
    name: "Cesta Organizadora Retangular",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898908706 64 2",
    measures: {
      depth: 250,
      width: 160,
      height: 65,
    },
    package: PackageEnumerator.SC,
    quantity: 72,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/664.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 345,
    name: "Gaveteiro com 2 gavetas",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 53 1",
    measures: {
      depth: 200,
      width: 140,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.WHITE, ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/345_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 346,
    name: "Gaveteiro com 3 gavetas",
    category: CategoryEnumerator.ORGANIZADORES,
    code: "7898947043 53 1",
    measures: {
      depth: 200,
      width: 140,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.WHITE, ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/organizadores/346_branco.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 350,
    name: "Black Box Multiuso 5 litros",
    category: CategoryEnumerator.BLACK,
    code: "7898947043 70 8",
    measures: {
      depth: 230,
      width: 180,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/black/350.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 351,
    name: "Black Box Multiuso 10 litros",
    category: CategoryEnumerator.BLACK,
    code: "7898947043 71 5",
    measures: {
      depth: 330,
      width: 240,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/black/351.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 352,
    name: "Black Box Multiuso 16 litros",
    category: CategoryEnumerator.BLACK,
    code: "7898947043 72 2",
    measures: {
      depth: 420,
      width: 270,
      height: 150,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/black/352.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 353,
    name: "Black Box Multiuso 30 litros",
    category: CategoryEnumerator.BLACK,
    code: "7898947043 73 9",
    measures: {
      depth: 460,
      width: 310,
      height: 300,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/black/353.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 356,
    name: "Caixa Prática Multiuso 5 litros Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 78 4",
    measures: {
      depth: 230,
      width: 180,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/356.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 360,
    name: "Caixa Prática Multiuso 10 litros Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 82 1",
    measures: {
      depth: 330,
      width: 240,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/360.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 361,
    name: "Caixa Prática Multiuso 16 litros Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 83 8",
    measures: {
      depth: 420,
      width: 270,
      height: 150,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/361_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 362,
    name: "Caixa Prática Multiuso 30 litros Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 83 8",
    measures: {
      depth: 460,
      width: 310,
      height: 300,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/362.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 357,
    name: "Caixa Retangular 600 ml Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 79 1",
    measures: {
      depth: 170,
      width: 115,
      height: 40,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/357_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/357_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/357_rosa.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 358,
    name: "Caixa Retangular 1 litro Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 80 7",
    measures: {
      depth: 200,
      width: 145,
      height: 55,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/358_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/358_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/358_rosa.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 359,
    name: "Caixa Retangular 2 litros Color",
    category: CategoryEnumerator.COLOR,
    code: "7898947043 81 4",
    measures: {
      depth: 240,
      width: 175,
      height: 70,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/359_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/359_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/color/359_rosa.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 385,
    name: "Kit Jarra 3 litros com 4 Copos 500ml",
    category: CategoryEnumerator.KITS,
    code: "7898679150 09 5",
    measures: {
      depth: 150,
      width: 280,
      height: 230,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/385.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 648,
    name: "Kit Lanche 3 peças",
    category: CategoryEnumerator.KITS,
    code: "7898908706 48 2",
    measures: {
      depth: 220,
      width: 90,
      height: 220,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.TURQUOISE, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/648.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 386,
    name: "Kit Jarra e Espremedor 1,8 litros",
    category: CategoryEnumerator.KITS,
    code: "7898679150 10 1",
    measures: {
      depth: 190,
      width: 180,
      height: 210,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/386.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/386_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 383,
    name: "Kit Travessas Ovais P, M, G",
    category: CategoryEnumerator.KITS,
    code: "7898679150 06 4",
    measures: {
      depth: 380,
      width: 250,
      height: 64,
    },
    package: PackageEnumerator.SC,
    quantity: 8,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_branco.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_branco_pack.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_verde_pack.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/383_vermelho_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 384,
    name: "Kit Caixas Retangulares P, M, G",
    category: CategoryEnumerator.KITS,
    code: "7898679150 08 8",
    measures: {
      depth: 240,
      width: 180,
      height: 160,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_PINK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/384.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/kits/384_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 685,
    name: "Cabide 5 peças Preto",
    category: CategoryEnumerator.ACESSORIOS,
    code: "7898908706 85 7",
    measures: {
      depth: 395,
      width: 7,
      height: 170,
    },
    package: PackageEnumerator.CX,
    quantity: 48,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/685_pack.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/685.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 382,
    name: "Cabide 10 peças Preto",
    category: CategoryEnumerator.ACESSORIOS,
    code: "7898679150 05 7",
    measures: {
      depth: 395,
      width: 7,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/382.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/685.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 387,
    name: "Cabide 6 peças Cristal",
    category: CategoryEnumerator.ACESSORIOS,
    code: "7898679150 11 8",
    measures: {
      depth: 395,
      width: 7,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/387.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/387_extra.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 632,
    name: "Suporte para Botijão com Rodízios",
    category: CategoryEnumerator.ACESSORIOS,
    code: "7898908706 32 1",
    measures: {
      depth: 290,
      width: 290,
      height: 60,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/632.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 610,
    name: "Relógio de Parede Marbella",
    category: CategoryEnumerator.ACESSORIOS,
    code: "7898908706 10 9",
    measures: {
      depth: 295,
      width: 295,
      height: 35,
    },
    package: PackageEnumerator.CX,
    quantity: 12,
    colors: [
      ColorEnumerator.GREEN,
      ColorEnumerator.RED,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BLACK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/610.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/acessorios/610_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6501,
    name: "Lixeira com Pedal 9,5 litros Preto",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 63 0",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6501.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6502,
    name: "Lixeira com Pedal 9,5 litros Branco",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 64 7",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6502.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6505,
    name: "Lixeira com Pedal 9,5 litros Verde",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 76 0",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.GREEN],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6505.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6506,
    name: "Lixeira com Pedal 9,5 litros Vermelho",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 77 7",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6506.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6507,
    name: "Lixeira com Pedal 9,5 litros Rosa",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898679150 13 2",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.BABY_PINK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6507.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 6508,
    name: "Lixeira com Pedal 9,5 litros Azul",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898679150 14 9",
    measures: {
      depth: 230,
      width: 230,
      height: 285,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.BABY_BLUE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/6508.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 639,
    name: "Lixeira com Tampa Móvel 3,6 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898908706 39 0",
    measures: {
      depth: 175,
      width: 175,
      height: 250,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.BLACK,
      ColorEnumerator.WHITE,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/639_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 625,
    name: "Cesto Telado 10 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898908706 25 3",
    measures: {
      depth: 245,
      width: 245,
      height: 270,
    },
    package: PackageEnumerator.SC,
    quantity: 48,
    colors: [
      ColorEnumerator.BLACK,
      ColorEnumerator.WHITE,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/625_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 3442,
    name: "Lixeira com Tampa Móvel 40 litros Preto",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 60 9",
    measures: {
      depth: 360,
      width: 480,
      height: 530,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/3442.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 3441,
    name: "Lixeira com Tampa Móvel 40 litros Branco",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 59 3",
    measures: {
      depth: 360,
      width: 480,
      height: 530,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/3441.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 3432,
    name: "Lixeira com Tampa 40 litros Preto",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 56 2",
    measures: {
      depth: 360,
      width: 480,
      height: 400,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/3432.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 3431,
    name: "Lixeira com Tampa 40 litros Branco",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 55 5",
    measures: {
      depth: 360,
      width: 480,
      height: 400,
    },
    package: PackageEnumerator.SC,
    quantity: 6,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/3431.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 320,
    name: "Lixeira com Tampa Móvel 12 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 20 3",
    measures: {
      depth: 245,
      width: 245,
      height: 380,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.BLACK, ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/304_branco.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/304_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 304,
    name: "Lixeira com Tampa 10 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 04 3",
    measures: {
      depth: 245,
      width: 245,
      height: 260,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [ColorEnumerator.BLACK, ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/304_preto.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/304_branco.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 319,
    name: "Balde 7 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 19 7",
    measures: {
      depth: 270,
      width: 270,
      height: 235,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/319_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/319_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 302,
    name: "Balde 14 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 02 9",
    measures: {
      depth: 300,
      width: 300,
      height: 290,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/302_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/302_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 317,
    name: "Bacia 5 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 17 3",
    measures: {
      depth: 320,
      width: 320,
      height: 120,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/317_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/317_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/317_natural.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 318,
    name: "Bacia 10 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 18 0",
    measures: {
      depth: 395,
      width: 395,
      height: 155,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/318_natural.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/318_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/318_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 303,
    name: "Bacia 18 litros",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898947043 03 6",
    measures: {
      depth: 425,
      width: 425,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/303_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/303_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/303_natural.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 678,
    name: "Pá de Lixo Limpá",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898908706 78 9",
    measures: {
      depth: 300,
      width: 240,
      height: 40,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.BLACK,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/678.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 662,
    name: "Cesta Prendedor com Alça",
    category: CategoryEnumerator.LIMPEZA,
    code: "7898908706 62 8",
    measures: {
      depth: 205,
      width: 110,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 72,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.BLACK,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/limpeza/662.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 368,
    name: "Travessa Oval Pequena",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 90 6",
    measures: {
      depth: 290,
      width: 170,
      height: 53,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/368_branco.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/368_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/368_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 369,
    name: "Travessa Oval Média",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 91 3",
    measures: {
      depth: 340,
      width: 210,
      height: 54,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/369_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/369_branco.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/369_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 370,
    name: "Travessa Oval Grande",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 92 0",
    measures: {
      depth: 380,
      width: 250,
      height: 64,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.WHITE,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/370_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/370_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/370_branco.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 689,
    name: "Petisqueira",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 89 5",
    measures: {
      depth: 200,
      width: 200,
      height: 35,
    },
    package: PackageEnumerator.CX,
    quantity: 60,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/689.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 609,
    name: "Fruteira com Pé Madrid",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 09 3",
    measures: {
      depth: 280,
      width: 280,
      height: 165,
    },
    package: PackageEnumerator.CX,
    quantity: 12,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.YELLOW,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/609_amarelo.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 675,
    name: "Porta Frios",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 75 8",
    measures: {
      depth: 230,
      width: 170,
      height: 80,
    },
    package: PackageEnumerator.CX,
    quantity: 12,
    colors: [ColorEnumerator.RED, ColorEnumerator.TURQUOISE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/675_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 602,
    name: "Saladeira Sobremesa Sevilha",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 02 4",
    measures: {
      depth: 120,
      width: 120,
      height: 55,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.BABY_PINK,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/602_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/602_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/602_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 601,
    name: "Saladeira Grande Sevilha 3,6 litros",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 01 7",
    measures: {
      depth: 300,
      width: 300,
      height: 110,
    },
    package: PackageEnumerator.CX,
    quantity: 24,
    colors: [
      ColorEnumerator.BABY_PINK,
      ColorEnumerator.BABY_BLUE,
      ColorEnumerator.BABY_GREEN,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/601_rosa.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/601_azul.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/601_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 679,
    name: "Saladeira Corazón",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 79 6",
    measures: {
      depth: 185,
      width: 215,
      height: 90,
    },
    package: PackageEnumerator.SC,
    quantity: 48,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/679_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 604,
    name: "Taça Coquetel Sobremesa Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 04 8",
    measures: {
      depth: 95,
      width: 95,
      height: 100,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/604_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/604_amarelo.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 603,
    name: "Taça Coquetel Grande Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 03 1",
    measures: {
      depth: 150,
      width: 150,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/603_amarelo.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/603_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 674,
    name: "Colher de Sorvete",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 74 1",
    measures: {
      depth: 150,
      width: 150,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/674_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/674_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 652,
    name: "Dúzia Colher de Sobremesa Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 52 9",
    measures: {
      depth: 150,
      width: 150,
      height: 170,
    },
    package: PackageEnumerator.CX,
    quantity: 60,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/652.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/652_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 316,
    name: "Jarra Valência 1,8 litros Graduada",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 16 6",
    measures: {
      depth: 130,
      width: 130,
      height: 210,
    },
    package: PackageEnumerator.CX,
    quantity: 24,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/316_natural.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/316_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 624,
    name: "Jarra Valência 3 litros",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 24 6",
    measures: {
      depth: 140,
      width: 140,
      height: 225,
    },
    package: PackageEnumerator.CX,
    quantity: 24,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/624_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/624_natural.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 611,
    name: "Jarra Espremedor 1 litro Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 11 6",
    measures: {
      depth: 125,
      width: 125,
      height: 110,
    },
    package: PackageEnumerator.CX,
    quantity: 48,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/611.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 614,
    name: "Jarra Graduada 1 litro Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 14 7",
    measures: {
      depth: 125,
      width: 125,
      height: 115,
    },
    package: PackageEnumerator.CX,
    quantity: 48,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.NATURAL,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/614.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 677,
    name: "Copo 300 ml Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 77 2",
    measures: {
      depth: 70,
      width: 70,
      height: 110,
    },
    package: PackageEnumerator.SC,
    quantity: 120,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/677_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/677_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 618,
    name: "Copo 500 ml Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 18 5",
    measures: {
      depth: 92,
      width: 92,
      height: 134,
    },
    package: PackageEnumerator.cx,
    quantity: 96,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/618_verde.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/618_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 617,
    name: "Caneca 300 ml Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 17 8",
    measures: {
      depth: 85,
      width: 85,
      height: 90,
    },
    package: PackageEnumerator.CX,
    quantity: 96,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/617_amarelo.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/617_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 623,
    name: "Caneca 500 ml Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 23 9",
    measures: {
      depth: 92,
      width: 92,
      height: 134,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/617_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/617_amarelo.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 620,
    name: "Bandeja Retangular 2,8 litros Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 20 8",
    measures: {
      depth: 290,
      width: 210,
      height: 70,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/620.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 305,
    name: "Bandeja Retangular 5,6 litros Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 05 0",
    measures: {
      depth: 380,
      width: 245,
      height: 70,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/305.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 670,
    name: "Bandeja Andorra",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 70 3",
    measures: {
      depth: 300,
      width: 195,
      height: 15,
    },
    package: PackageEnumerator.SC,
    quantity: 36,
    colors: [ColorEnumerator.RED, ColorEnumerator.TURQUOISE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/670_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 615,
    name: "Bandeja Redonda Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 15 4",
    measures: {
      depth: 360,
      width: 360,
      height: 35,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/615_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 342,
    name: "Pote Multiuso",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 50 0",
    measures: {
      depth: 175,
      width: 175,
      height: 175,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.BLACK, ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/342_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 622,
    name: "Forma de Gelo Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 22 2",
    measures: {
      depth: 270,
      width: 105,
      height: 35,
    },
    package: PackageEnumerator.SC,
    quantity: 72,
    colors: [ColorEnumerator.WHITE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/622.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 626,
    name: "Prato Fundo Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 26 0",
    measures: {
      depth: 205,
      width: 205,
      height: 35,
    },
    package: PackageEnumerator.SC,
    quantity: 72,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/626_vermelho.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/626_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 630,
    name: "Tábua Multiuso Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 30 7",
    measures: {
      depth: 360,
      width: 190,
      height: 3,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/630_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 680,
    name: "Porta Cebola",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 80 2",
    measures: {
      depth: 110,
      width: 110,
      height: 100,
    },
    package: PackageEnumerator.CX,
    quantity: 96,
    colors: [ColorEnumerator.RED, ColorEnumerator.TURQUOISE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/680_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 646,
    name: "Porta Óleo",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 46 8",
    measures: {
      depth: 85,
      width: 85,
      height: 130,
    },
    package: PackageEnumerator.SC,
    quantity: 50,
    colors: [
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.BLACK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/646_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 673,
    name: "Funil",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 73 4",
    measures: {
      depth: 135,
      width: 125,
      height: 160,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [ColorEnumerator.RED, ColorEnumerator.TURQUOISE],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/673_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 688,
    name: "Porta Filtro 103",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 88 8",
    measures: {
      depth: 125,
      width: 125,
      height: 120,
    },
    package: PackageEnumerator.CX,
    quantity: 48,
    colors: [ColorEnumerator.BLACK],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/688.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 301,
    name: "Escorredor de Louças",
    category: CategoryEnumerator.COZINHA,
    code: "7898947043 01 2",
    measures: {
      depth: 470,
      width: 300,
      height: 70,
    },
    package: PackageEnumerator.SC,
    quantity: 12,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.BLACK,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/301.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 640,
    name: "Porta Sabão e Detergente Valência",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 40 6",
    measures: {
      depth: 260,
      width: 125,
      height: 80,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.BLACK,
      ColorEnumerator.WHITE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/640_preto.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 661,
    name: "Secador de Talheres",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 61 1",
    measures: {
      depth: 185,
      width: 65,
      height: 110,
    },
    package: PackageEnumerator.CX,
    quantity: 60,
    colors: [ColorEnumerator.TURQUOISE, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/661_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 660,
    name: "Porta Talheres",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 60 4",
    measures: {
      depth: 320,
      width: 245,
      height: 45,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.TURQUOISE,
      ColorEnumerator.RED,
      ColorEnumerator.BLACK,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/660_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 643,
    name: "Lava Tudo Madrid",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 43 7",
    measures: {
      depth: 240,
      width: 200,
      height: 90,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [ColorEnumerator.TURQUOISE, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/643_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 621,
    name: "Lava Arroz Barcelona",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 21 5",
    measures: {
      depth: 260,
      width: 230,
      height: 170,
    },
    package: PackageEnumerator.SC,
    quantity: 24,
    colors: [ColorEnumerator.TURQUOISE, ColorEnumerator.RED],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/621_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 668,
    name: "Saleiro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 68 0",
    measures: {
      depth: 90,
      width: 140,
      height: 60,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [
      ColorEnumerator.BABY_PINK,
      ColorEnumerator.BABY_GREEN,
      ColorEnumerator.BABY_BLUE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/668_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 644,
    name: "Ralador 4 Faces",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 44 4",
    measures: {
      depth: 120,
      width: 100,
      height: 155,
    },
    package: PackageEnumerator.CX,
    quantity: 72,
    colors: [ColorEnumerator.GREY],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/644.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 616,
    name: "Forma de Bolo e Pudim 2 litros para Micro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 16 1",
    measures: {
      depth: 215,
      width: 215,
      height: 80,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/616.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/616_extra.jpg",
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/616_pack.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 629,
    name: "Panela de Arroz para Micro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 29 1",
    measures: {
      depth: 200,
      width: 200,
      height: 115,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/629.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 638,
    name: "Tampa para Micro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 38 3",
    measures: {
      depth: 215,
      width: 215,
      height: 60,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [ColorEnumerator.NATURAL],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/638.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 606,
    name: "Tigela Pequena com Tampa Madrid 0,8 litro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 06 2",
    measures: {
      depth: 170,
      width: 170,
      height: 85,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/606_amarelo.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 607,
    name: "Tigela Média com Tampa Madrid 1,5 litro",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 07 9",
    measures: {
      depth: 190,
      width: 190,
      height: 120,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/607_verde.jpg",
    ],
    new: false,
    isActive: true,
  },
  {
    ref: 608,
    name: "Tigela Grande com Tampa Madrid 2,6 litros",
    category: CategoryEnumerator.COZINHA,
    code: "7898908706 08 6",
    measures: {
      depth: 210,
      width: 210,
      height: 150,
    },
    package: PackageEnumerator.CX,
    quantity: 36,
    colors: [
      ColorEnumerator.YELLOW,
      ColorEnumerator.RED,
      ColorEnumerator.TURQUOISE,
    ],
    images: [
      "https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/linhas/cozinha/608_vermelho.jpg",
    ],
    new: false,
    isActive: true,
  },
]

export default products
